<template>
	<div class="find">
		<div class="head">
			<img src="../assets/head.png">
		</div>
		<div class="step" v-if="step1">
			<van-form @submit="onSubmit">
				<van-field
						v-model="key"
						name="key"
						label="券号"
						placeholder="券号"
						:rules="[{ required: true, message: '请输入卡券号码（10位数字）' }]"
				/>
				<div style="margin: 16px;">
					<van-button round block type="info" native-type="submit">查询</van-button>
				</div>
			</van-form>
		</div>
		<div class="step" v-if="step2">
			<van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">提货信息</van-divider>
			<van-cell title="券号" v-bind:value="key" size="large" />
			<van-cell title="名称" v-bind:value="couponName" size="large" />
			<van-cell title="数量" v-bind:value="couponNum" size="large" />
			<van-cell title="配送方式" v-bind:value="shipping" size="large" />
			<van-cell title="状态" v-bind:value="status" size="large" />
			<div v-if="step2_1">
				<van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">提货地址</van-divider>
				<van-cell title="自提地址" value="江苏省苏州市吴江区黎里镇莘塔张圆东路58号小陈蟹行" size="large" />
				<van-cell title="联系电话" value="18019246150" size="large" />
			</div>
			<div v-if="step2_2">
				<van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">收货信息</van-divider>
				<van-cell title="快递单号" v-bind:value="express_number" size="large" />
				<van-address-list
						v-model="chosenAddressId"
						:list="list"
						default-tag-text="大闸蟹配送地址"
						@edit="onEdit"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import {Form} from 'vant';
	import { Field } from 'vant';
	import { Button } from 'vant';
	import { Dialog } from 'vant';
	import { AddressList } from 'vant';
	import { Cell, CellGroup } from 'vant';
	import { Divider } from 'vant';

	Vue.use(Divider);
	Vue.use(Cell);
	Vue.use(AddressList);
	Vue.use(Dialog);
	Vue.use(Form);
	Vue.use(Field);
	Vue.use(Button);
	export default {
		name: 'find',
		data() {
			return {
				key: '',
				step1:true,
				step2:false,
				step2_1:false,
				step2_2:false,
				shipping: '',
				couponName: '',
				express_number: '',
				couponNum: '',
				status: '',
				chosenAddressId: '1',
				list: [
					{
						id: '1',
						name: '',
						tel: '',
						address: '',
						isDefault: true,
					},
				]
			};
		},
		methods: {
			onSubmit(values) {
				const that = this
				this.$axios({
					method: "post",//指定请求方式
					url: "index/couponQuery",//请求接口（相对接口，后面会介绍到）
					data: values
				}).then(function(res){
					let data = JSON.parse(res.data);
					if (data.code == 1){
						that.step1 = false
						that.step2 = true
						that.key = data.data.key
						that.shipping = data.data.shipping
						that.couponName = data.data.name
						that.couponNum = data.data.number
						that.express_number = data.data.express_number
						that.status = data.data.status
						that.list[0].name = data.data.realname
						that.list[0].tel = data.data.mobile
						that.list[0].address = data.data.address
						if (data.data.shipping == '自提'){
							that.step2_1 = true;
							that.step2_2 = false;
						}else{
							that.step2_1 = false;
							that.step2_2 = true;
						}
					}else{
						Dialog.alert({
							title: '查询失败',
							message: data.msg,
						}).then(() => {

						});
					}
					//接口成功返回结果执行
				}).catch(function(err){
					//请求失败或者接口返回失败或者.then()中的代码发生错误时执行
				})

			},
			onEdit(item, index) {
				Dialog.alert({
					title: '无法编辑',
					message: '目前不支持编辑收货信息，请致电客服进行修改！',
				}).then(() => {

				});
			},
		},
	}
</script>


<style scoped>
	.head{
		text-align: center;
		margin: 10px 0 10px 0;
	}

	.head img{
		width: 90%;
		margin: 10px 0 30px 0;
		/*padding: 0 20px 0 20px;*/
	}
	.van-address-list__bottom{
		display: none;
	}
	.step{
		margin:40px 0 0 0;
	}
</style>
